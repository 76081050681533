import { createGlobalStyle } from 'styled-components';
import PropaneSVGBackground from '../images/the-propane-home-bg-en.jpg';
import HeleveticNeue from '../../static/fonts/HelveticaNeue-Light.woff';
import HeleveticNeueWoff2 from '../../static/fonts/HelveticaNeue-Light.woff2';
import HeleveticNeueBold from '../../static/fonts/HelveticaNeue-Bold.woff';
import HeleveticNeueBoldWoff from '../../static/fonts/HelveticaNeue-Bold.woff2';
import HeleveticNeueRoman from '../../static/fonts/HelveticaNeue-Roman.woff';
import HeleveticNeueRomanWoff from '../../static/fonts/HelveticaNeue-Roman.woff2';
import FigtreeRegular from '../../static/fonts/Figtree-Regular.woff';
import FigtreeLight from '../../static/fonts/Figtree-Light.woff';
import FigtreeMedium from '../../static/fonts/Figtree-Medium.woff';
import FigtreeBold from '../../static/fonts/Figtree-Bold.woff';
import FigtreeBlack from '../../static/fonts/Figtree-Black.woff';
import FigtreeBlack2 from '../../static/fonts/Figtree-Black.woff2';

const SlpGlobalStyles = createGlobalStyle`
/* This is used to test if the stylesheet has been loaded yet*/
@font-face {
    font-family: 'Helvetica Neue';
    src: url(${HeleveticNeueWoff2}) format('woff2'),
    url(${HeleveticNeueBold}) format('woff'),
    url(${HeleveticNeueBoldWoff}) format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
  font-family: "Figtree-Regular";
  src: url(${FigtreeRegular}) format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Figtree-Light";
  src: url(${FigtreeLight}) format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Figtree-Medium";
  src: url(${FigtreeMedium}) format('woff');
font-weight: normal;
font-style: normal;
}
@font-face {
  font-family: "Figtree-Bold";
  src: url(${FigtreeBold}) format('woff');
  font-weight: bold;
  font-style: normal;
}
  @font-face {
  font-family: "Figtree-Black";
  src: url(${FigtreeBlack2}) format('woff2'),
  url(${FigtreeBlack}) format('woff');
  font-weight: normal;
  font-style: normal;
}
.propaneHome{
	.st0{fill:#3A2417;}
	.st1{fill:#FFFFFF;}
	.st2{fill: #00493B;}
	.svgTextContainer{
		:hover{
			cursor: pointer;
			.st2{fill: #FFC429;};
			}
	}
	svg{
		display: block;
		top: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: auto;
		margin: 0 auto;
		background: url(${PropaneSVGBackground}) no-repeat center left;
		background-size: 100% auto;
		:focus{
			outline: none;
		}
	
		@media (max-width: 767px){
			width: 100%;
			position: static;
		}
	}
}

@media (max-width: 767px){
	.tooltip-furnace-boiler{
		transform: translate(-20%, -40%);
	}
	.tooltip-water-heater{
		transform: translate(5%,-30%);
	}
	.tooltip-bbq{
		transform: translate(-35%,-15%);	
	}
	.tooltip-generator{
		transform: translate(-30%, 25%);	
	}
	.tooltip-heat-pump{
		transform: translate(0%, -20%);
	}
	.tooltip-gas-range{
		transform: translate(-20%, -20%);
	}
}

.propaneHome-tooltip {
	/* width: 425px;
    padding: 40px;
	font-size: 16px;
    line-height: 22px; */
	width: 250px;
    font-size: 9px;
    line-height: 12px;
    padding: 20px;
    background: #00493B;
    border-radius: 6px;
	text-align: center;
	font-family: 'HelveticaNeue-Light', arial, sans-serif;
    margin-bottom: 0;
    color: #fff;
	@media (max-width: 767px){
		width: 425px;
    	font-size: 16px;
    	line-height: 22px;
	}
	@media (max-width: 575px){
		width: 425px;
    	font-size: 24px;
    	line-height: 26px;
	}
}
`;
export default SlpGlobalStyles;
